import type { FeedbacksQuery_feedbacks_FeedbackPaginationHandler_records_Feedback as Feedback } from '~/libs/gql/queries/feedback/feedbacks.generated';

export type AdminRestaurantFeedbackState = {
  detailFeedbackId: number | null
  feedbackDrawerOpen: boolean
  showFeedbackDetails: boolean
  showMarkAllReadDialog: boolean
}

/** @internal this export is for tests */
export const initialAdminRestaurantFeedbackState: AdminRestaurantFeedbackState = {
  detailFeedbackId: null,
  feedbackDrawerOpen: false,
  showFeedbackDetails: false,
  showMarkAllReadDialog: false,
};

export type AdminRestaurantFeedbackEvent =
  | { type: 'openDetailFeedback', context: { feedback: Pick<Feedback, 'id' | '__typename'> } }
  | { type: 'closeDetailFeedback' }
  | { type: 'openMarkAllReadDialog', context: { fromFeedbackDrawer: boolean } }
  | { type: 'closeMarkAllReadDialog' }

export const adminRestaurantFeedbackReducer = (state: AdminRestaurantFeedbackState = initialAdminRestaurantFeedbackState, event: AdminRestaurantFeedbackEvent): AdminRestaurantFeedbackState => {
  const newState = { ...state };
  switch (event.type) {
    case 'openDetailFeedback':
      if (event.context.feedback) {
        newState.detailFeedbackId = event.context.feedback.id;
        newState.showFeedbackDetails = true;
      }
      return newState;
    case 'closeDetailFeedback':
      newState.detailFeedbackId = null;
      newState.showFeedbackDetails = false;
      return newState;
    case 'openMarkAllReadDialog':
      newState.showMarkAllReadDialog = true;
      if (event.context.fromFeedbackDrawer) {
        newState.feedbackDrawerOpen = true;
      }
      return newState;
    case 'closeMarkAllReadDialog':
      newState.showMarkAllReadDialog = false;
      newState.feedbackDrawerOpen = false;
      return newState;
    default:
      return newState;
  }
};
